import { Link } from "gatsby"
import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Footer from "../../components/footer"
import Head from "../../components/header"
import Layout from "../../components/layout"
import TopMenu from "../../components/menu"

const RecruitingPage = () => (
  <Layout>
    <Head pageTitle="Recruiting - GAZIRU, Inc." pageLang="en" />
    <TopMenu />
    <Container>
      <Row>
        <Col sm={1}>
        </Col>
        <Col sm={10}>
          <h4>Recruiting</h4>
          <h5>Asking for help</h5>
          <br />
          <p>We are currently accepting applications for sales and planning positions.
            If you are interested in applying, please feel free to contact us using <Link className="text-decoration-none" to="/notification/contact/en">this contact form</Link>.</p>

          <table>
            <tbody>
              <tr>
                <th>Job Position</th>
                <td>Sales and Planning</td>
              </tr>
              <tr>
                <th>Employment status</th>
                <td>Full-time employee</td>
              </tr>
              <tr>
                <th>Remuneration</th>
                <td>Negotiable</td>
              </tr>
              <tr>
                <th>Work location</th>
                <td>Setagaya Ward, Tokyo</td>
              </tr>
              <tr>
                <th>Working hours</th>
                <td>9:00 - 17:45 (7.75 hours of actual work)</td>
              </tr>
              <tr>
                <th>Holidays</th>
                <td>
                  2 days off per week (Sat, Sun, national holidays)<br />
                  Year-end and New Year vacations<br />
                  Annual leave<br />
                  Summer vacation, etc.
                </td>
              </tr>
            </tbody>
          </table>

          <br />

          <p>Please apply using <Link className="text-decoration-none" to="/notification/contact/en">the contact form</Link>.</p>
          <br />
          <br />
          <br />
          <p><Link className="text-decoration-none" to="/">Home</Link></p>
          <br />
          <br />
        </Col>
        <Col sm={1}>
        </Col>
      </Row>
    </Container>
    <Footer />
  </Layout>
)

export default RecruitingPage
